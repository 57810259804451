import EnvironmentTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/environment/EnvironmentTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinDryness/Environment/EnvironmentTemplate',
  component: EnvironmentTemplate
};

const createStory = props => () => ({
  components: { EnvironmentTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><environment-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  drynessCausedByClimate: BOOLEAN.YES,
  title:
    'The reason I feel dryness on my skin is because it is cold and dry in London at the moment '
});
